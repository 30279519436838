<template>
  <!-- <div class="card card-custom">
      <div v-if="ddd">
        <div class="text-center p-5">
          <b-spinner variant="primary" label="Text Centered"></b-spinner>
        </div>
      </div>
      <div v-show="!ddd">
        <div class="card-body">
          <div>
            <v-tabs v-model="tab">
              <v-tab v-for="item in items" :key="item.tab">
                {{ item.tab }}
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              
              <v-tab-item>
                <v-card>
                  <v-card-text>
                    <div>
                      <div class="pb-5">
                        <div v-if="getOnlyDetails.juridical_type == 'JI'">
                          <h4 class="mb-5 ">
                            Jismoniy shaxs
                          </h4>
                          <div class="input__wrapper">
                            <div class="col-6">
                              <v-text-field
                                dense
                                label="Mijoz nomi"
                                outlined
                                disabled
                                :value="getOnlyDetails.full_name"
                              ></v-text-field>
                            </div>
                            <div class="col-6">
                              <v-text-field
                                dense
                                label="Pochta manzili"
                                outlined
                                disabled
                                :value="getOnlyDetails.pochta_address"
                              ></v-text-field>
                            </div>

                            <div class="col-3">
                              <v-text-field
                                dense
                                label="Pasport raqami"
                                outlined
                                disabled
                                :value="getOnlyDetails.passport_number"
                              ></v-text-field>
                            </div>

                            <div class="col-3">
                              <v-text-field
                                dense
                                label="Oblast"
                                outlined
                                disabled
                                :value="getOnlyDetails.adress_oblast.name"
                              ></v-text-field>
                            </div>

                            <div class="col-3">
                              <v-text-field
                                dense
                                label="Region"
                                outlined
                                disabled
                                :value="getOnlyDetails.adress_region.name"
                              ></v-text-field>
                            </div>

                            <div class="col-3">
                              <v-text-field
                                dense
                                label="Status"
                                outlined
                                disabled
                                :value="getOnlyDetails.status.status"
                              ></v-text-field>
                            </div>

                            <div class="col-3">
                              <v-text-field
                                dense
                                label="Izoh"
                                outlined
                                disabled
                                :value="getOnlyDetails.comments"
                              ></v-text-field>
                            </div>
                          </div>
                        </div>

                        <div v-if="getOnlyDetails.juridical_type == 'YU'">
                          <h5 class="mb-10 text-dark">
                            Yuridik shaxs
                          </h5>
                          <div class="input__wrapper">
                            <div class="col-3">
                              <v-text-field
                                dense
                                label="Mijoz nomi"
                                outlined
                                disabled
                                :value="getOnlyDetails.full_name"
                              ></v-text-field>
                            </div>

                            <div class="col-3">
                              <v-text-field
                                dense
                                label="INN"
                                outlined
                                disabled
                                :value="getOnlyDetails.inn"
                              ></v-text-field>
                            </div>

                            <div class="col-3">
                              <v-text-field
                                dense
                                label="OKED"
                                outlined
                                disabled
                                :value="getOnlyDetails.oked"
                              ></v-text-field>
                            </div>

                            <div class="col-3">
                              <v-text-field
                                dense
                                label="NDS raqami"
                                outlined
                                disabled
                                :value="getOnlyDetails.code_nds"
                              ></v-text-field>
                            </div>

                            <div class="col-3">
                              <v-text-field
                                dense
                                label="Oblast"
                                outlined
                                disabled
                                :value="getOnlyDetails.adress_oblast.name"
                              ></v-text-field>
                            </div>

                            <div class="col-3">
                              <v-text-field
                                dense
                                label="Region"
                                outlined
                                disabled
                                :value="getOnlyDetails.adress_region.name"
                              ></v-text-field>
                            </div>

                            <div class="col-3">
                              <v-text-field
                                dense
                                label="Status"
                                outlined
                                disabled
                                :value="getOnlyDetails.status.status"
                              ></v-text-field>
                            </div>

                            <div class="col-3">
                              <v-text-field
                                dense
                                label="Mulchilik shakli"
                                outlined
                                disabled
                                :value="getOnlyDetails.ownership.name"
                              ></v-text-field>
                            </div>

                            <div class="col-3">
                              <v-text-field
                                dense
                                label="Pochta manzili"
                                outlined
                                disabled
                                :value="getOnlyDetails.pochta_address"
                              ></v-text-field>
                            </div>

                            <div class="col-6">
                              <v-text-field
                                dense
                                label="Yuridik manzil"
                                outlined
                                disabled
                                :value="getOnlyDetails.yur_address"
                              ></v-text-field>
                            </div>

                            <div class="col-3">
                              <v-text-field
                                dense
                                label="Izoh"
                                outlined
                                disabled
                                :value="getOnlyDetails.comments"
                              ></v-text-field>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              
              <v-tab-item>
                <v-card>
                  <v-card-text>
                    <div>
                      <div class="pb-5" data-wizard-type="step-content">
                        <h5 class="mb-10 text-dark">
                          Kontakt malumotlari
                        </h5>
                        <div class="border-bottom mb-5 pb-5">
                          <div>
                            <div>
                              <h4 class="mb-5">Direktor:</h4>
                              <div>
                                <v-text-field
                                  dense
                                  label="FIO"
                                  outlined
                                  disabled
                                  :value="getOnlyDetails.director_name"
                                ></v-text-field>
                              </div>
                              <div class="d-flex">
                                <div class="col-6 pl-0">
                                  <v-text-field
                                    dense
                                    label="Telefon raqam"
                                    outlined
                                    disabled
                                    :value="getOnlyDetails.director_phone1"
                                  ></v-text-field>
                                </div>
                                <div class="col-6 pr-0">
                                  <v-text-field
                                    dense
                                    label="Telefon raqam"
                                    outlined
                                    disabled
                                    :value="getOnlyDetails.director_phone2"
                                  ></v-text-field>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div
                                class="separator separator-primary  separator-dashed mt-8 mb-5"
                              ></div>
                              <div>
                                <h4 class="mb-5">Hodimlar:</h4>
                                <v-text-field
                                  dense
                                  label="Hodim FIO:"
                                  outlined
                                  disabled
                                  :value="getOnlyDetails.contact_personal_name"
                                ></v-text-field>
                                <div class="d-flex">
                                  <div class="col-4 pl-0">
                                    <v-text-field
                                      dense
                                      label="Lavozim:"
                                      outlined
                                      :value="
                                        getOnlyDetails.contact_personal_position_name
                                      "
                                      disabled
                                    ></v-text-field>
                                  </div>

                                  <div class="col-4 ">
                                    <v-text-field
                                      dense
                                      label="Hodim telefon raqami:"
                                      outlined
                                      disabled
                                      :value="
                                        getOnlyDetails.contact_personal_phone1
                                      "
                                    ></v-text-field>
                                  </div>

                                  <div class="col-4">
                                    <v-text-field
                                      dense
                                      label="Hodim telefon raqami:"
                                      outlined
                                      disabled
                                      :value="
                                        getOnlyDetails.contact_personal_phone2
                                      "
                                    ></v-text-field>
                                  </div>
                                </div>

                                <div
                                  class="separator separator-primary  separator-dashed mt-8 mb-5"
                                ></div>
                              </div>
                            </div>
                            <div>
                              <div>
                                <h4 class="mb-10">
                                  Bank malumotlari:
                                </h4>
                                <div class="d-flex">
                                  <div class="col-6 pl-0">
                                    <v-text-field
                                      dense
                                      label="Bank mfo raqami:"
                                      outlined
                                      disabled
                                      :value="getOnlyDetails.bank_code1"
                                    ></v-text-field>
                                  </div>

                                  <div class="col-6 pr-0">
                                    <v-text-field
                                      dense
                                      label="Band hisob raqami:"
                                      outlined
                                      disabled
                                      :value="getOnlyDetails.bank_account1"
                                    ></v-text-field>
                                  </div>
                                </div>

                                <div
                                  class="separator separator-primary  separator-dashed mt-8 mb-5"
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              
              <v-tab-item>
                <v-card>
                  <v-card-text>
                    <div>
                      <div class="pb-5" data-wizard-type="step-content">
                        <div v-if="getClientContracts.length == 0">
                          <h5 class="mb-10 text-dark">
                            Shartnoma ruyhati topilmadi
                          </h5>
                        </div>
                        <div v-else>
                          <h5 class="mb-10 text-dark">
                            Shartnoma ruyhati
                          </h5>
                          <v-data-table
                            :headers="headers"
                            :items="getClientContracts"
                            hide-default-footer
                          >
                            <template v-slot:[`item.is_active`]="{ item }">
                              <div v-if="item.is_active">
                                <span
                                  class="label label-success label-dot"
                                ></span
                                >&nbsp;
                                <span class=" text-success">Faol</span>
                              </div>
                              <div v-else>
                                <span
                                  class="label label-primary label-dot"
                                ></span
                                >&nbsp;
                                <span class="text-danger">Foal emas</span>
                              </div>
                            </template>
                            <template
                              v-slot:[`item.parent_contract`]="{ item }"
                            >
                              <div v-if="item.parent_contract == null">
                                Shartnoma
                              </div>
                              <div v-else>Kelishuv</div>
                            </template>
                            <template v-slot:[`item.contract_type`]="{ item }">
                              <div
                                class="status__name"
                                :class="item.contract_class_name"
                              >
                                {{ item.contract_type }}
                              </div>
                            </template>
                            <template v-slot:[`item.action`]="{ item }">
                              <ContractsAction :index="item"></ContractsAction>
                            </template>
                          </v-data-table>
                        </div>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </div>
          
        </div>
      </div>
    </div> -->

  <div class="card card-custom h-100">
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-3"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
        id="kt_wizard_v3"
      >
        <!--begin: Wizard Nav -->
        <div class="wizard-nav border-bottom mb-1 mb-lg-5">
          <div class="wizard-steps px-8 py-8 px-lg-15 py-lg-3">
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <h3 class="wizard-title">Umumiy malumotlar</h3>
                <div class="wizard-bar"></div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <h3 class="wizard-title">Kontakt malumotlari</h3>
                <div class="wizard-bar"></div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <h3 class="wizard-title">Shartnomalar ruyhati</h3>
                <div class="wizard-bar"></div>
              </div>
            </div>

            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <h3 class="wizard-title">Boshlang’ich qoldiq</h3>
                <div class="wizard-bar"></div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav -->
        <div v-if="loading" class="text-primary text-center my-5">
          <b-spinner
            style="width: 3rem; height: 3rem"
            label="Large Spinner"
          ></b-spinner>
        </div>
        <!--begin: Wizard Body-->
        <div
          v-if="!loading"
          class="row flex-1 justify-content-center px-8 px-lg-10"
        >
          <div class="col-12">
            <!--begin: Wizard Form-->
            <form class="form" id="kt_form" v-if="list !== undefined">
              <!--begin: Wizard Step 1-->
              <div
                v-if="list.juridical_type == 'JI'"
                class="pb-5"
                data-wizard-type="step-content"
                data-wizard-state="current"
              >
                <h4 class="mb-10 font-weight-bold text-dark">Jismoniy shaxs</h4>
                <div class="row">
                  <div class="col-xl-4">
                    <div class="form-group">
                      <label>Mijoz nomi</label>
                      <input
                        disabled
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        :value="list.full_name"
                      />
                    </div>
                  </div>

                  <div class="col-xl-4">
                    <div class="form-group">
                      <label>Qo'shimcha manzil</label>
                      <input
                        type="text"
                        disabled
                        class="form-control form-control-solid form-control-lg"
                        :value="list.pochta_address"
                      />
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <div class="form-group">
                      <label>Pasport raqami</label>
                      <input
                        disabled
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        :value="list.passport_number"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-4">
                    <div class="form-group">
                      <label>Oblast</label>
                      <!-- <input
                          disabled
                          type="text"
                          class="form-control form-control-solid form-control-lg"
                          :value="list.adress_oblast.name"
                        /> -->
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <div class="form-group" v-if="list.adress_region !== null">
                      <label>Region</label>
                      <!-- <input
                          disabled
                          type="text"
                          class="form-control form-control-solid form-control-lg"
                          name="state"
                          placeholder="State"
                          :value="list.adress_region.name"
                        /> -->
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <div class="form-group">
                      <label>Status</label>
                      <input
                        type="text"
                        disabled
                        class="form-control form-control-solid form-control-lg"
                        name="state"
                        placeholder="State"
                        :value="list.status.status"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <label>Izoh</label>
                      <textarea
                        class="form-control form-control-solid form-control-lg"
                        :value="list.comments"
                        disabled
                        cols="30"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <!--end: Wizard Step 1-->

              <div
                v-if="list.juridical_type == 'YU'"
                class="pb-5"
                data-wizard-type="step-content"
                data-wizard-state="current"
              >
                <h4 class="mb-10 font-weight-bold text-dark">Yuridik shaxs</h4>
                <div class="row">
                  <div class="col-xl-4">
                    <div class="form-group">
                      <label>Mijoz nomi</label>
                      <input
                        disabled
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        :value="list.full_name"
                      />
                    </div>
                  </div>

                  <div class="col-xl-4">
                    <div class="form-group">
                      <label>INN</label>
                      <input
                        type="text"
                        disabled
                        class="form-control form-control-solid form-control-lg"
                        :value="list.inn"
                      />
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <div class="form-group">
                      <label>OKED</label>
                      <input
                        disabled
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        :value="list.oked"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-4">
                    <div class="form-group">
                      <label>NDS raqami</label>
                      <input
                        disabled
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        :value="list.code_nds"
                      />
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <div class="form-group">
                      <label>Oblast</label>
                      <input
                        v-if="list.adress_oblast"
                        disabled
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="state"
                        placeholder="State"
                        :value="list.adress_oblast.name"
                      />
                      <input
                        v-else
                        disabled
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="state"
                        placeholder="State"
                        value="Topilmadi"
                      />
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <div class="form-group" v-if="list.adress_region !== null">
                      <label>Region</label>
                      <input
                        v-if="list.adress_region"
                        disabled
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="state"
                        placeholder="State"
                        :value="list.adress_region.name"
                      />
                      <input
                        v-else
                        disabled
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="state"
                        placeholder="State"
                        value="Topilmadi"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-4">
                    <div class="form-group">
                      <label>Status</label>
                      <input
                        disabled
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        :value="list.status.status"
                      />
                    </div>
                  </div>

                  <div class="col-xl-4">
                    <div class="form-group" v-if="list.ownership !== null">
                      <label>Mulchilik shakli</label>
                      <input
                        disabled
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        :value="list.ownership.name"
                      />
                    </div>
                  </div>

                  <div class="col-xl-4">
                    <div class="form-group">
                      <label>Qo'shimcha manzil</label>
                      <input
                        disabled
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        :value="list.pochta_address"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-4">
                    <div class="form-group">
                      <label>Yuridik manzil</label>
                      <input
                        disabled
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        :value="list.yur_address"
                      />
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <div class="form-group">
                      <label>ID</label>
                      <input
                        disabled
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        :value="list.id"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <label>Izoh</label>
                      <textarea
                        class="form-control form-control-solid form-control-lg"
                        :value="list.comments"
                        disabled
                        cols="30"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <!--end: Wizard Step 1-->

              <!--begin: Wizard Step 2-->
              <div
                class="pb-5"
                data-wizard-type="step-content"
                v-if="list.juridical_type !== 'YU'"
              >
                <h4 class="mb-10 font-weight-bold text-danger">
                  Jismoniy shaxs uchun kontakt malumotlari kiritilmagan
                </h4>
              </div>

              <div class="pb-5" data-wizard-type="step-content" v-else>
                <h4 class="mb-5 font-weight-bold text-dark">Direktor</h4>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <label>Direktor nomi</label>
                      <input
                        disabled
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="state"
                        placeholder="State"
                        :value="list.director_name"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Telefon raqami</label>
                      <input
                        disabled
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="state"
                        placeholder="Kiritilmagan"
                        :value="list.director_phone1"
                      />
                    </div>
                  </div>

                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Telefon raqami</label>
                      <input
                        disabled
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="state"
                        placeholder="Kiritilmagan"
                        :value="list.director_phone2"
                      />
                    </div>
                  </div>
                </div>
                <h4 class="my-5 font-weight-bold text-dark">Mas'ul xodim</h4>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <label>Mas'ul xodim nomi</label>
                      <input
                        disabled
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="state"
                        :value="list.contact_personal_name"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Telefon raqami</label>
                      <input
                        disabled
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="state"
                        placeholder="Kiritilmagan"
                        :value="list.contact_personal_phone1"
                      />
                    </div>
                  </div>

                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Telefon raqami</label>
                      <input
                        disabled
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="state"
                        placeholder="Kiritilmagan"
                        :value="list.contact_personal_phone2"
                      />
                    </div>
                  </div>
                </div>
                <h4 class="my-5 font-weight-bold text-dark">
                  Bank malumotlari
                </h4>
                <div class="row">
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Bank mfo raqami</label>
                      <input
                        disabled
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="state"
                        placeholder="State"
                        :value="list.bank_code1"
                      />
                    </div>
                  </div>

                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Bank hisob raqami</label>
                      <input
                        disabled
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="state"
                        placeholder="State"
                        :value="list.bank_account1"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <!--end: Wizard Step 2-->

              <!--begin: Wizard Step 3-->
              <div class="pb-5 h-min-400px" data-wizard-type="step-content">
                <div v-if="getClientContracts.length == 0">
                  <h4 class="mb-10 font-weight-bold text-danger">
                    Mijoz nomiga ochilgan shartnomalar mavjud emas
                  </h4>
                </div>
                <div v-else>
                  <v-data-table
                    :headers="headers"
                    no-data-text="Malumotlar kiritilmagan"
                    :items="getClientContracts"
                    hide-default-footer
                  >
                    <template v-slot:[`item.start_date`]="{ item }">
                      {{ item.start_date | dateFilter }}
                    </template>
                    <template v-slot:[`item.end_date`]="{ item }">
                      {{ item.end_date | dateFilter }}
                    </template>
                    <template v-slot:[`item.amount`]="{ item }">
                      {{ item.amount | mask }}
                    </template>
                    <template v-slot:[`item.is_active`]="{ item }">
                      <div v-if="item.is_active">
                        <span class="label label-success label-dot"></span
                        >&nbsp;
                        <span class="text-success">Faol</span>
                      </div>
                      <div v-else>
                        <span class="label label-primary label-dot"></span
                        >&nbsp;
                        <span class="text-danger">Foal emas</span>
                      </div>
                    </template>
                    <template v-slot:[`item.parent_contract`]="{ item }">
                      <div v-if="item.parent_contract == null">Shartnoma</div>
                      <div v-else>Kelishuv</div>
                    </template>
                    <template v-slot:[`item.contract_type`]="{ item }">
                      <div
                        class="status__name"
                        :class="item.contract_class_name"
                      >
                        {{ item.contract_type }}
                      </div>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                      <ContractsAction :index="item"></ContractsAction>
                    </template>
                  </v-data-table>
                </div>
              </div>
              <!--end: Wizard Step 3-->

              <!--begin: Wizard Step 4-->
              <div class="pb-5" data-wizard-type="step-content">
                <!-- <div v-if="getStartingPoint && getStartingPoint.length == 0">
                    <h4 class="mb-10 font-weight-bold text-danger">
                      Boshlang'ich qoldiq mavjud emas
                    </h4>
                  </div> -->
                <div class="row justify-content-end">
                  <div class="col">
                    <v-btn
                      @click="dialog = true"
                      :loading="isLoading"
                      small
                      color="primary"
                    >
                      +
                    </v-btn>
                  </div>
                </div>
                <div>
                  <v-data-table
                    :headers="startingHeaders"
                    no-data-text="Malumotlar kiritilmagan"
                    :items="getStartingPoint"
                    hide-default-footer
                  >
                    <template v-slot:[`item.oper_date`]="{ item }">
                      {{ item.oper_date | dateFilter }}
                    </template>
                    <template v-slot:[`item.debet_amount`]="{ item }">
                      {{ item.debet_amount }}
                    </template>
                    <template v-slot:[`item.credit_amount`]="{ item }">
                      {{ item.credit_amount }}
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                      <button
                        type="button"
                        @click="edit(item)"
                        class="
                          btn btn-icon btn-light btn-hover-primary btn-sm
                          mx-3
                        "
                      >
                        <span class="svg-icon svg-icon-md svg-icon-primary">
                          <!--begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/Communication/Write.svg-->
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              stroke-width="1"
                              fill="none"
                              fill-rule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24"></rect>
                              <path
                                d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                                fill="#000000"
                                fill-rule="nonzero"
                                transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953)"
                              ></path>
                              <path
                                d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                                fill="#000000"
                                fill-rule="nonzero"
                                opacity="0.3"
                              ></path>
                            </g>
                          </svg>
                          <!--end::Svg Icon-->
                        </span>
                      </button>
                      <button
                        @click="deletePoint(item.id)"
                        type="button"
                        class="btn btn-icon btn-light btn-hover-primary btn-sm"
                      >
                        <span class="svg-icon svg-icon-md svg-icon-primary">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              stroke-width="1"
                              fill="none"
                              fill-rule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24"></rect>
                              <path
                                d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                fill="#000000"
                                fill-rule="nonzero"
                              ></path>
                              <path
                                d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                fill="#000000"
                                opacity="0.3"
                              ></path>
                            </g>
                          </svg>
                        </span>
                      </button>
                    </template>
                  </v-data-table>
                </div>
                <v-dialog v-model="dialog" persistent max-width="600px">
                  <v-card>
                    <v-card-title>
                      <span class="headline">
                        Boshlang'ich qoldiq kiritish
                      </span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="4">
                            <v-menu
                              v-model="menu2"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  dense
                                  outlined
                                  v-model="date"
                                  label="Sana"
                                  prepend-inner-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="date"
                                @input="menu2 = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              label="Debet"
                              v-model="debet"
                              v-currency="options"
                              outlined
                              dense
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-currency="options"
                              outlined
                              dense
                              v-model="credit"
                              label="Credit"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="
                          dialog = false
                          creating = true
                        "
                      >
                        Bekor qilish
                      </v-btn>
                      <v-btn
                        :loading="isLoading"
                        color="blue darken-1"
                        text
                        @click="send"
                      >
                        Saqlash
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
              <!--end: Wizard Step 4-->
            </form>
            <!--end: Wizard Form-->
          </div>
        </div>
        <!--end: Wizard Body-->
      </div>
      <!--end: Wizard-->
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

import KTWizard from '@/assets/js/components/wizard'
import Swal from 'sweetalert2'
import ContractsAction from '@/view/content/dropdown/ContractsAction.vue'

export default {
  name: 'clientDetails',
  data() {
    return {
      editingId: '',
      creating: true,
      debet: '',
      credit: '',
      selectedCurrencyOption: 1,
      currencyCode: 'USD',
      distractionFree: false,
      hideCurrencySymbol: true,
      hideGroupingSymbol: true,
      hideNegligibleDecimalDigits: true,
      prefix: null,
      suffix: null,
      precisionEnabled: true,
      precisionRangeEnabled: false,
      precisionFixed: 0,
      precisionRange: [0, 20],
      valueRangeEnabled: false,
      valueRange: [0, 9999],
      date: '',
      menu2: false,
      dialog: false,
      list: {},
      loading: true,
      selected_opt: '',
      passportNumber: '',
      ddd: true,
      perPage: 10,
      currentPage: 1,
      tab: null,
      items: [
        {
          tab: 'Umumiy malumotlar'
        },
        {
          tab: 'Kontakt malumotlari'
        },
        {
          tab: 'Shartnomalari ruyhati'
        }
      ],
      headers: [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: 'Nomi',
          value: 'client_name'
        },
        {
          text: 'Kontakt nomeri',
          key: 'contract_number'
        },
        {
          text: 'Tugash',
          value: 'end_date'
        },
        {
          text: 'Boshlanish',
          value: 'start_date'
        },
        {
          text: 'INN',
          value: 'client_inn'
        },
        {
          text: 'Summa',
          value: 'amount'
        },
        {
          text: 'Kategoriya',
          value: 'parent_contract'
        },
        {
          text: 'Status',
          value: 'is_active'
        },
        {
          text: 'Turi',
          value: 'contract_type'
        },
        {
          value: 'action',
          text: '',
          sortable: false
        }
      ],
      fields: [
        {
          label: '#',
          key: 'index'
        },
        {
          label: 'ID',
          key: 'id'
        },
        {
          label: 'Nomi',
          key: 'client_name'
        },
        {
          label: 'Kontakt nomeri',
          key: 'contract_number'
        },
        {
          label: 'Tugash',
          key: 'end_date'
        },
        {
          label: 'Boshlanish',
          key: 'start_date'
        },
        {
          label: 'INN',
          key: 'client_inn'
        },
        {
          label: 'Summa',
          key: 'amount'
        },
        {
          label: 'Kategoriya',
          key: 'parent_contract'
        },
        {
          label: 'Statusi',
          key: 'is_active'
        },
        {
          label: 'Turi',
          key: 'contract_type'
        },
        {
          key: 'actions',
          label: ''
        }
      ]
    }
  },
  beforeCreate() {
    this.$store.dispatch('getClientDetails', this.$route.params.id)
    this.$store.dispatch('getOneClientContract', this.$route.params.id)

    this.$store.dispatch('getStartingPoint', this.$route.params.id)
    this.$store.dispatch('staffPositions')
  },
  filters: {
    mask: (val) => {
      if (Boolean(val) && typeof val !== 'string') {
        return val.toLocaleString('es-US')
      }
    }
  },
  created() {
    // this.ddd = this.$store.state.requests.testShow;
  },
  updated() {
    this.ddd = this.$store.state.requests.testShow
    this.initWizard()
  },
  components: {
    ContractsAction
  },
  computed: {
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    startingHeaders() {
      return [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: 'Sana',
          value: 'oper_date'
        },
        {
          text: 'Debet',
          value: 'debet_amount'
        },
        {
          text: 'Kredit',
          value: 'credit_amount'
        },
        {
          text: '',
          value: 'action'
        }
      ]
    },
    getStartingPoint() {
      return this.$store.state.requests.allStartingPoint.results
    },
    getSHow() {
      return this.$store.state.requests.testShow
    },
    getClientDetails() {
      return this.$store.state.requests.CurrentClientDetails
    },
    getClientContracts() {
      return this.$store.state.requests.oneClientContract
    },
    getOnlyDetails() {
      return this.$store.state.requests.clientDetails
    },
    options() {
      return {
        locale: 'zh-ZH',
        currency: [null],
        valueRange: this.valueRangeEnabled
          ? { min: this.valueRange[0], max: this.valueRange[1] }
          : undefined,
        precision: this.precisionEnabled
          ? this.precisionRangeEnabled
            ? { min: this.precisionRange[0], max: this.precisionRange[1] }
            : this.precisionFixed
          : undefined,
        distractionFree: this.distractionFree,
        autoDecimalMode: this.autoDecimalMode,
        valueAsInteger: this.valueAsInteger,
        allowNegative: this.allowNegative
      }
    }
  },
  watch: {
    getOnlyDetails: function (val) {
      this.list = {}
      this.list = val
      this.loading = false
    },

    isLoading(val) {
      if (!val) {
        this.initWizard()
      }
    }
  },
  methods: {
    edit(payload) {
      this.editingId = payload.id
      this.creating = false
      this.dialog = true
      this.date = payload.oper_date
      this.debet = payload.debet_amount.toLocaleString('es-US')
      this.credit = payload.credit_amount.toLocaleString('es-US')
    },
    deletePoint(payload) {
      Swal.fire({
        title: 'Tasdiqlang',
        text: "Ma'lumot ochrilganidan so'ng qayta tiklay olmaysiz!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: "Xa, o'chirish",
        cancelButtonText: 'Bekor qilish'
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire('Ochirildi!', '', 'success')
          this.$store.dispatch('deleteStartingPoint', {
            id: payload,
            client: this.$route.params.id
          })
        }
      })
    },
    send() {
      if (this.date !== '' && this.debet !== '' && this.credit !== '') {
        const data = {
          client: this.$route.params.id,
          oper_date: this.date,
          debet_amount: parseInt(this.debet.replace(/[ ,]/g, ''), 10),
          credit_amount: parseInt(this.credit.replace(/[ ,]/g, ''), 10)
        }
        if (this.creating) {
          this.$store.dispatch('createStartingPoint', data).then(() => {
            this.dialog = false
            this.date = ''
            this.debet = ''
            this.credit = ''
          })
        } else {
          this.$store
            .dispatch('updateStartingPoint', { data: data, id: this.editingId })
            .then(() => {
              this.dialog = false
              this.date = ''
              this.debet = ''
              this.credit = ''
            })
        }
      } else {
        Swal.fire({
          title: '',
          text: 'Formada xatolik bor',
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary'
        })
      }
    },

    initWizard() {
      this.$nextTick(() => {
        // Initialize form wizard
        const wizard = new KTWizard('kt_wizard_v3', {
          startStep: 1, // initial active step number
          clickableSteps: true // allow step clicking
        })

        // Validation before going to next page
        wizard.on('beforeNext', function (/*wizardObj*/) {
          // validate the form and use below function to stop the wizard's step
          // wizardObj.stop();
        })
      })
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Mijozlar' },
      { title: 'Mijoz malumotlari' }
    ])

    this.initWizard()
  }
}
</script>
<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-3.scss';
</style>
<style scoped>
.status__name {
  border-radius: 5px;
  text-align: center;
  color: #fff;
  padding: 1px 4px;
}
.wizard-step {
  padding: 0;
}
</style>
